import React, { useState, useEffect } from 'react'
import TTHeader from './TTHeader'
import useBodyAreas from '../hooks/useBodyAreas'
import AreasContainer from './AreasContainer'
import TTOutputs from './TTOutputs'
import { updateSelections } from './utils/updateSelections'

export const TxTimeContext = React.createContext()

function TTWrapper() {
  const parentsAreasArr = useBodyAreas()
  const [selectedAreas, setSelectedAreas] = useState([])
  const [message, setMessage] = useState(null)

  useEffect(() => {
    updateSelections(parentsAreasArr, selectedAreas)
  }, [selectedAreas])

  // useEffect(() => {
  //   if (message) {
  //     setTimeout(() => {
  //       setMessage(null)
  //     }, 5000)
  //   }
  // }, [message])

  // Context Values to be passed into nested components
  const contextValue = {
    selectedAreas,
    setSelectedAreas,
    parentsAreasArr,
    setMessage,
    message
  }

  return (
    <div className="container-xxl milan-bg-white">
      <TTHeader />
      <TxTimeContext.Provider value={contextValue}>
        {
          parentsAreasArr.length > 0 &&
          <>
            <AreasContainer />
            <TTOutputs />
          </>
        }
      </TxTimeContext.Provider>
    </div>
  )
}

export default TTWrapper