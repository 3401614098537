import { forbiddenCombo, allParentsIds, combinable } from './combos'

// the larger area could be 0 or more than 1 (like fult_t_shirt) // Use .filter NOT .find
const filterComboElem = (combo, parentId) => {
  let filteredCombo = combo.filter(item => item.parentId === parentId)
  if (filteredCombo.length > 0) {
    return {
      parentId: filteredCombo[0].parentId,
      childrenIds: filteredCombo.map(obj => obj.childrenIds).flat()
    }
  } else return undefined
}

/**
 * @function excludeChildren - detects children areas in the selectedAreas array if any
 * @param {Object} largerArea - potintially includes smaller body areas
 * @param {Array} selectedAreas - Array of the selected body areas
 * @returns a (filtered array with no children areas, after replacing them with the larger area & allChildren ids) OR undefinded
 */
export const excludeChildren = (largerArea, selectedAreas) => {
  if (allParentsIds.some(id => id === largerArea.id)) { // When a larger area is added (Full Legs)
    let allChildren = []
    const getAllChildren = (parentId) => {
      if (allParentsIds.includes(parentId)) {
        // Find the larger area in combinable array if any
        let comboElem1 = filterComboElem(combinable, parentId)
        // Find the larger area in forbiddenCombo array if any
        let comboElem2 = filterComboElem(forbiddenCombo, parentId)
        // Combine the two
        let children = (comboElem1 && comboElem2) ? Array.from(new Set([...comboElem1.childrenIds, ...comboElem2.childrenIds])) : comboElem1 ? comboElem1.childrenIds : comboElem2.childrenIds
        children.forEach(child => getAllChildren(child))
        allChildren.push(children)
      }
    }

    getAllChildren(largerArea.id)
    // Use new Set to get rid of the duplicates
    allChildren = Array.from(new Set(allChildren.flat()))

    // Get the ones to be excluded
    let excluded = selectedAreas.filter(area => allChildren.includes(area.id))
    // Get rid of all the smaller areas
    let filteredSelectedAreas = selectedAreas.filter(area => !allChildren.includes(area.id))
    // Add the larger area
    filteredSelectedAreas.push(largerArea)

    return {
      filteredSelectedAreas,
      allChildren,
      excluded
    }
  }
  else return undefined
}