import React, { useEffect, useState, useContext } from 'react'
import { TxTimeContext } from './TTWrapper'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import AreasList from './AreasList'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { excludeChildren } from './utils/excludeChildren'

export function AreasContainer() {
  const { parentsAreasArr, selectedAreas, message } = useContext(TxTimeContext)

  // Not using parentsAreasArr directly because it doesn't get updated when areas get selected or un-selected
  const [parentsAreas, setParentsAreas] = useState(parentsAreasArr)
  const [activeKey, setActiveKey] = useState(0)

  let allDisabledAreas = []
  selectedAreas.forEach(area => {
    let excludedChildren = excludeChildren(area, selectedAreas)
    if (excludedChildren) allDisabledAreas.push(...excludedChildren.allChildren)
  })

  useEffect(() => {
    // This will get the areas updated / Usefull to check for the "selected" property
    const parentsAreasClone = [...parentsAreas]
    setParentsAreas(parentsAreasClone)
  }, [selectedAreas])

  return (
    <>
      <section className="row">
        <Tabs
          id="body-areas-tabs"
          activeKey={activeKey}
          transition={false}
          onSelect={(k) => setActiveKey(k)}
          className={`body-areas-tabs justify-content-start padding-x flex-nowrap pe-0`}
        >
          {
            parentsAreas.map(parentArea => (
              <Tab key={parentArea.index} eventKey={parentArea.index} title={parentArea.name}>
                <AreasList {...{ parentsAreas, parentArea, allDisabledAreas }} />
              </Tab>
            ))
          }
        </Tabs>
      </section>
      <section className="row py-3">
        <div className="col">
          <p className="mb-0 text-center fw-bold" style={{ minHeight: '24px' }}>
            {message && <>
              <FontAwesomeIcon className="milan-text-tur fs-5 me-2" icon={faTriangleExclamation} /> <span>{message}</span>
            </>}
          </p>
        </div>
      </section>
    </>

  )
}

export default AreasContainer