import React, { useContext } from 'react'
import { TxTimeContext } from './TTWrapper'
import { checkForSubset } from './utils/checkForSubset'
import { checkForLarger } from './utils/checkForLarger'
import { excludeChildren } from './utils/excludeChildren'
import { combinable } from './utils/combos'

function AreasList({ parentsAreas, parentArea, allDisabledAreas }) {
  const { selectedAreas, setSelectedAreas, setMessage } = useContext(TxTimeContext)

  const addBodyArea = area => {
    setMessage(null)
    // Important: Notice here we're only updating the selectedAreas array
    // Setting the body area to be (selected or unselected) doesn't actually happen here

    // If the area is already selected
    if (area.selected && area.selected === true) {
      setSelectedAreas([...selectedAreas.filter(item => item.id !== area.id)])
    } else { // If the area is NOT selected
      // Do NOT add any areas if Full Body was added previously
      // When Full Body is added, it's always the first element in the selelctedAreas array
      if (selectedAreas[0]?.id === 'full_body') {
        setMessage(`Full body includes ${area.name}.`)
        return
      }
      // Make a copy of the area to avoid altering the original area object
      const areaClone = { ...area }
      areaClone.parentAreaId = parentArea.id // Parent Area ID: this is usefull in other components
      // When adding Full Body, Replace everything else
      if (area.id === 'full_body') {
        if (selectedAreas.length > 0) setMessage("Full body replaced all previous selected body areas.")
        setSelectedAreas([areaClone])
      }
      else {
        // Make a copy of the selectedAreas to avoid altering the original selectedAreas array
        let selectedAreasTemp = [...selectedAreas]

        // This will trigger when the selectedAreas has at least one area
        if (selectedAreas.length >= 1) {
          // At this point, when adding a second area, selectedAreas array is still not updated yet. Therefore, the length is still = 1
          // Add the new area to the selectedAreasTemp
          selectedAreasTemp.push(areaClone)

          // When 3 or more Full Face & Neck children areas are selected
          // This part is only to display a feedback message - It's NOT doing anything else
          const FFN = combinable.find(elem => elem.parentId === 'full_face_and_neck')
          if (FFN.childrenIds.includes(area.id)) {
            const ffnAreas = selectedAreasTemp.filter(area => FFN.childrenIds.includes(area.id))
            if (ffnAreas.length > 2) {
              const ffnAreasNames = ffnAreas.map(ffnArea => ffnArea.name).join(' + ')
              setMessage(`(${ffnAreasNames}) combination will not exceed 20 minutes.`)
            }
          }

          // When a smaller area is added, check if selectedAreas already has a related larger area
          let largerAreaDetected = checkForLarger(areaClone, selectedAreas)
          if (largerAreaDetected) {
            setMessage(`${areaClone.name} is already included in ${largerAreaDetected.name}.`)
            return // Return with no action
          }
          else {
            // IMPORTANT: keep it in this order
            // 1. When a larger area is added -> Check for possible smaller areas to be removed
            let excludedChildren = excludeChildren(areaClone, selectedAreas)
            if (excludedChildren) {
              if (excludedChildren.filteredSelectedAreas.length <= selectedAreas.length) {
                let excluded = excludedChildren.excluded.map(area => area.name)
                setMessage(`${areaClone.name} includes: (${excluded.join(' + ')}).`)
              }
              selectedAreasTemp = excludedChildren.filteredSelectedAreas
            }

            // 2. When a smaller area is added, Check for possible larger area to replace added smaller areas 
            let subset = checkForSubset(areaClone, selectedAreasTemp, parentArea, parentsAreas)
            if (subset) {
              let combination = subset.combination.map(area => area.name)
              setMessage(`${subset.largerArea.name} replaced (${combination.join(' + ')}).`)
              selectedAreasTemp = subset.combinedSelections
            }
            setSelectedAreas(selectedAreasTemp)
          }
        } else { // When adding the first body area
          setSelectedAreas([areaClone])
        }
      }
    }
  }

  const AreaButton = ({ area }) => {
    let isDisabled = selectedAreas.length > 0 && (selectedAreas[0].id === 'full_body' && area.id !== 'full_body') || allDisabledAreas.some(id => id === area.id)

    return (
      <button type='button'
        className={`sub-area-item rounded px-3 ${area.selected && area.selected === true ? 'selected' : ''} ${isDisabled ? 'disabled' : ''}`}
        onClick={() => addBodyArea(area)}>
        {area.name}
      </button>
    )
  }

  return (
    <ul id={parentArea.id} className="row flex-wrap padding-x g-3 my-4">
      {
        parentArea.areas.map(area => (
          <li key={area.id} className="col-auto">
            <AreaButton area={area} />
          </li>
        ))
      }
    </ul>
  )
}

export default AreasList