import { combinable, combinableIds } from "./combos"

/**
 * @function isSubset - Checks if any of the selected areas are combinable - returns a new array of areas if possible
 * @param {Object} area - the body area to be added
 * @param {Array} selectedAreas - Array of selected areas by the user
 * @param {Array} combinable - combinable is an Array and each element is an object of a larger area (id) and a samller array of combinable areas (ids)
 */
export function checkForSubset(area, selectedAreas, parentArea, parentsAreas) {
  if (combinableIds.some(id => id === area.id)) {
    // Get all the areas ids of the selectedAreas 
    let ids = selectedAreas.map(area => area.id) // Array
    /**
     * @param {Object} obj - combinable array element consists of parentId & childernIds props
     */
    const checkCombinable = obj => {
      // if the children array has more items than the selectedAreas array -> selected areas can't be combinalbe
      if (obj.childrenIds.length > selectedAreas.length) return false
      let joinedIds = [...ids, ...obj.childrenIds] // When some areas are combinable, there will be duplicates
      let setOfIds = Array.from(new Set(joinedIds)) // Set gets rid of duplicates
      return (joinedIds.length - setOfIds.length) === obj.childrenIds.length
    }
    let combinableElem = combinable.find(checkCombinable)
    if (combinableElem) {
      let combination = selectedAreas.filter(area => combinableElem.childrenIds.includes(area.id))
      // Exclude the (children) small combinable areas
      let filteredSelectedAreas = selectedAreas.filter(area => !combinableElem.childrenIds.includes(area.id))

      // Find the larger area object
      // Note: most of the times, the larger area is just another area element of parentArea.areas
      let largerArea = parentArea.areas.find(area => area.id === combinableElem.parentId)
      if (largerArea) largerArea.parentAreaId = parentArea.id
      // But sometimes it's not. so expand your search 
      if (!largerArea) {
        let possibleParentIds = Array.from(new Set(selectedAreas.map(area => area.parentAreaId).filter(id => id !== parentArea.id)))
        let possibleParents = parentsAreas.filter(parent => possibleParentIds.some(id => id === parent.id))
        let parent = possibleParents.find(group => group.areas.find(area => area.id === combinableElem.parentId))
        largerArea = parent.areas.find(area => area.id === combinableElem.parentId)
        if (largerArea) largerArea.parentAreaId = parent.id
      }
      if (largerArea) {
        filteredSelectedAreas.push(largerArea)
        // Keep repeating the same process till you find all the combinable areas
        let subset = checkForSubset(largerArea, filteredSelectedAreas, parentArea, combinable)
        if (subset?.isSubset && subset.combinedSelections) {
          return {
            isSubset: true,
            combinedSelections: subset.combinedSelections,
            largerArea,
            combination
          }
        } else {
          return {
            isSubset: true,
            combinedSelections: filteredSelectedAreas,
            largerArea,
            combination
          }
        }
      }
    }
  } else return undefined
}