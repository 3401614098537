export const updateSelections = (parentsAreasArr, selectedAreas) => {
  // 1. Unselect all items initially
  parentsAreasArr.forEach(parentArea => parentArea.areas.forEach(area => area.selected = false))
  // 2. Loop through the items in the selected areas
  selectedAreas.forEach(item => {
    // 3. Find the index of the corresponding parent area in the parentsAreasArr
    const parentAreaIndex = parentsAreasArr.findIndex(parentArea => parentArea.id === item.parentAreaId)
    // 4. Find the child area as well
    const foundArea = parentsAreasArr[parentAreaIndex].areas.find(area => area.id === item.id)
    // 5. Set "selected" to be true
    foundArea.selected = true
  })
}