import React, { useContext } from 'react'
import { TxTimeContext } from './TTWrapper'
import TrashCan from '../images/Trash-Can.png'
import Card from 'react-bootstrap/Card'
import { sizesTimes } from './utils/sizesTimes'
import { combinable } from './utils/combos'

function TTOutputs() {
  const { selectedAreas, setSelectedAreas, setMessage } = useContext(TxTimeContext)
  const deleteArea = area => {
    setSelectedAreas([...selectedAreas.filter(elem => elem.id !== area.id)])
    setMessage(null)
  }

  // Use the spread operator to avoid altering the original array
  const sortedSelectedAreas = [...selectedAreas].sort((a, b) => sizesTimes[b.size] - sizesTimes[a.size]) // First element will be the biggest time
  // 1. Start with 10 minutes initially
  // 2. Subtract 10 minutes from each area
  // 3. Calculate the times
  let minutes = sortedSelectedAreas.reduce(function (acc, area) { return acc + (sizesTimes[area.size] - 10) }, 10)
  // Up until now, the calculation would be correct for all body sizes except for XS areas
  // The above reduce func makes each XS area time = 0 minutes, which is incorrect when adding 2 or more XS areas. Therefore,
  // 4. 10 minutes should be added for each XS areas (Except the first XS area) when 2 or more XS areas are selected
  // Get the count of all XS areas if any
  const XS_Areas = sortedSelectedAreas.filter(area => area.size === 'XS')
  const XS_AreasCount = XS_Areas.length
  // Note: When ALL the selected areas are XS (condition2 below), the calculation is a bit different
  /**
   * condition1 is false -> expression3
   * condition1 & condition2 are true -> expression1
   * condition1 is true but condition2 is false -> expression2
   */
  minutes += (XS_AreasCount >= 2) ? (XS_AreasCount === sortedSelectedAreas.length) ? (10 * (XS_AreasCount - 1) - 10) : (10 * (XS_AreasCount - 1)) : 0

  // ***** UNIQUE SITUATION ***** //
  // Full Face & Neck is a combination of 7 smaller areas ['unibrow', 'upper_lip', 'chin', 'cheeks', 'neck', 'sideburns', 'jawline']
  // Full Face & Neck tx time is 20 minutes. However, each of its children time is 10 minutes
  // When selecting 3 or more of its children, the sum will exceed their parent's time and that doesn't make sense. Therefore,
  if (XS_AreasCount > 2) {
    const FFN = combinable.find(elem => elem.parentId === 'full_face_and_neck')
    const ffnAreas = XS_Areas.filter(area => FFN.childrenIds.includes(area.id))
    if (ffnAreas.length > 2) {
      // If ALL the selected areas are children of Full Face & Neck
      if (ffnAreas.length === selectedAreas.length) minutes = 20
      else minutes -= 10 * (ffnAreas.length - 2)
    }
  }

  // ***** FINALLY: Minutes cannot exceed 120 ***** //
  if (minutes > 120) minutes = 120

  const clearAllAreas = () => {
    setSelectedAreas([])
    setMessage(null)
  }

  return (
    <section className="row justify-content-center pb-5 pt-4">
      <div className="col-md-6 col-lg-4">
        <div className='h-100'>
          <Card className={`tt-card card-border`}>
            <div className={`output text-center pt-4 milan-bg-light-tur rounded-top`}>
              <h2 className="milan-text-primary d-h text-uppercase mb-2">Treatment Time</h2>
              <p className="milan-text-navy text-center mb-0">Schedule the appointment for</p>
              <Card.Body className="py-2">
                <h3 className="text-center milan-text-tur display-6 mb-4 lh-1">
                  {selectedAreas.length > 0 ? <div className="mt-2">
                    <span>{minutes}</span>
                    <span className="fs-3 fw-500 d-block">Minutes</span>
                  </div> : <span>_ _ _</span>}
                </h3>
                <p className="milan-text-navy text-center"><strong>Reminder:</strong> Add 5 Minutes to First Treatments.</p>
              </Card.Body>
            </div>
            <Card.Body id="tt-items" className="p-0 rounded-bottom">
              {selectedAreas.length > 0 && selectedAreas.map((area, x) =>
                <div key={x} className="container py-2 border-bottom">
                  <div className="row justify-content-between align-items-center gx-1">
                    <div className="col">
                      <p className="mb-0">{area.name}</p>
                    </div>
                    <div className="col-auto print-hide">
                      <span className="pointer px-2" onClick={() => deleteArea(area)}>
                        <img src={TrashCan} alt="trash can icon" style={{ maxWidth: '18px' }} />
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </Card.Body>
          </Card>
          <div className="text-center mt-4 print-hide">
            <button
              type="button"
              className="milan-btn milan-primary-btn clear-btn"
              onClick={clearAllAreas}
              disabled={selectedAreas.length === 0}>
              Clear All Areas
            </button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default TTOutputs