import React from 'react'
import MILAN_LOGO_WHITE_WIDE from '../images/Milan_Logo_White_Wide.svg'

function TTHeader() {
  return (
    <header className={`row milan-bg-tur py-4 padding-x`}>
      <div className="col">
        <img className="logo" src={MILAN_LOGO_WHITE_WIDE} alt="Milan Laser Logo" />
      </div>
    </header>
  )
}

export default TTHeader