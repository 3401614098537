// All the below ids are gender neutral
export const forbiddenCombo = [
  {
    parentId: 'brazilian',
    childrenIds: ['bikini_line', 'perirectal']
  },
  {
    parentId: 'full_back',
    childrenIds: ['half_back']
  },
  {
    parentId: 'back_t_shirt',
    childrenIds: ['full_back']
  },
  {
    parentId: 'front_t_shirt',
    childrenIds: ['chest_abdomen']
  },
  {
    parentId: 'chest',
    childrenIds: ['areola']
  },
  {
    parentId: 'abdomen',
    childrenIds: ['navel']
  },
]

// Also any combinable parent with any of its children is considered a forbidden combination
export const combinable = [
  {
    parentId: 'full_legs',
    childrenIds: ['lower_legs', 'upper_legs']
  },
  {
    parentId: 'full_arms',
    childrenIds: ['forearms', 'upper_arms']
  },
  {
    parentId: 'brazilian_plus',
    childrenIds: ['brazilian', 'buttocks']
  },
  {
    parentId: 'chest_abdomen',
    childrenIds: ['abdomen', 'chest']
  },
  {
    parentId: 'full_face_and_neck',
    childrenIds: ['unibrow', 'upper_lip', 'chin', 'cheeks', 'neck', 'sideburns', 'jawline']
  },
  {
    parentId: 'full_t_shirt',
    childrenIds: ['full_back', 'chest_abdomen', 'shoulder', 'upper_arms']
  },
  {
    parentId: 'full_t_shirt',
    childrenIds: ['front_t_shirt', 'back_t_shirt']
  },
]

export const allParentsIds = Array.from(new Set([...forbiddenCombo.map(elem => elem.parentId), ...combinable.map(elem => elem.parentId)]))
// Get children ids
export const combinableIds = combinable.map(obj => obj.childrenIds).flat()
const forbiddenChildrenIds = Array.from(new Set(forbiddenCombo.map(obj => obj.childrenIds).flat()))
export const allChildrenIds = [...combinableIds, ...forbiddenChildrenIds]