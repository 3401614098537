import { forbiddenCombo, combinable, allChildrenIds } from './combos'
/**
  * @function checkForLarger - checks if the selected areas has a related largeArea area
  * @param {Object} smallArea - a to be added small area 
  * @param {Array} selectedAreas - array of selected areas
  * @returns object or undefinded
  */
export const checkForLarger = (smallArea, selectedAreas) => {
  // Check if selectedAreas has the corresponding largeArea area
  if (allChildrenIds.some(id => id === smallArea.id)) {
    // Find the largeArea area of the (to be added) small area
    const findLarger = combo => combo.find(elem => elem.childrenIds.some(item => item === smallArea.id))
    // IMPORTANT: Start with forbiddenCombo first, then check combinable
    let largeArea = findLarger(forbiddenCombo) || findLarger(combinable)

    let foundLarger = selectedAreas.find(item => item.id === largeArea.parentId)
    if (foundLarger) return foundLarger
    else {
      // Keep repeating the same process until the largest area is found
      largeArea.id = largeArea.parentId
      return checkForLarger(largeArea, selectedAreas)
    }
  } else return undefined // When no larger area is found
}